import React, { useEffect, useState } from 'react';
import UserContext from './context/userContext.js';
import { Container } from 'react-bootstrap';
import Routing from './components/routing/routing.js';
import Header from './components/base/header.js';
import Footer from './components/base/footer.js';
import User from './components/base/User.js';
//Test build
export default function App() {
  const [token, setToken] = useState();
  const [vaccineCode, setVaccineCode] = useState();
  const [schoolCode, setSchoolCode] = useState();
  const [consentLetterReferenceCode, setConsentLetterReferenceCode] =
    useState();

  const loginProcess = (userDetail, consentLetterReferenceCode) => {
    localStorage.setItem('token', userDetail.token);
    localStorage.setItem('vaccineCode', userDetail.vaccineCode);
    localStorage.setItem('schoolCode', userDetail.schoolCode);
    localStorage.setItem(
      'consentLetterReferenceCode',
      consentLetterReferenceCode,
    );
    setVaccineCode(userDetail.vaccineCode);
    setSchoolCode(userDetail.schoolCode);
    setConsentLetterReferenceCode(consentLetterReferenceCode);
    setToken(userDetail.token);
  };

  const logoutProcess = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('vaccineCode');
    localStorage.removeItem('schoolCode');
    localStorage.removeItem('consentLetterReferenceCode');
    setToken(null);
    setVaccineCode(null);
    setSchoolCode(null);
    setConsentLetterReferenceCode(null);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const vaccineCode = localStorage.getItem('vaccineCode');
    const schoolCode = localStorage.getItem('schoolCode');
      const consentLetterReferenceCode = localStorage.getItem(
          'consentLetterReferenceCode',
      );

    if (token) {
      setVaccineCode(vaccineCode);
      setSchoolCode(schoolCode);
      setConsentLetterReferenceCode(consentLetterReferenceCode);
      setToken(token);
    } else {
      setToken(null);
      setVaccineCode(null);
      setSchoolCode(null);
      setConsentLetterReferenceCode(null);
    }

    window.addEventListener('beforeunload', logoutProcess);

    return () => {
      window.removeEventListener('beforeunload', logoutProcess);
    };

  }, []);

  return (
    <>
      <Header />
      <div className="content">
        <Container>
           <UserContext.Provider
            value={{
              token,
              vaccineCode,
              schoolCode,
              consentLetterReferenceCode,
            }}
          >
            <User logoutProcess={logoutProcess} />
            <Routing loginProcess={loginProcess} />
          </UserContext.Provider> 
          
        </Container>
      </div>
      <Footer />
    </>
  );
}
